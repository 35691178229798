import axios from "axios";
import { AlertForm } from "@/types/alert_form";
import { AlertFormNoticeSetting } from '@/types/alert_form_notice_setting';
import { AlertFormAndNoticeSetting } from '@/types/alert_form_and_notice_setting';
import { AlertFormReport} from "@/types/alert_form_report";

export type GetFormReportParams = {
  limited_at: string;
}
export type ProjectIds = {
  project_ids: [];
}

export class AlertFormsRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor() {
    this.url = AlertFormsRepository.DOMAIN + "v1/alert_forms/";
  }
  async getReport(): Promise<AlertForm[]> {
    return axios.get(this.url).then((response) => {
      return response.data.alert_forms;
    });
  }
  async add(alertForm: AlertForm, alertFormNotisceSetting: AlertFormNoticeSetting, projectIds: ProjectIds ): Promise<AlertForm> {
    return axios.post(this.url, { alert_form: alertForm, alert_form_notice_setting: alertFormNotisceSetting, project_ids: projectIds}).then((response) => {
      return response.data;
    });
  }
  async get(alert_id: number): Promise<AlertFormAndNoticeSetting> {
    return axios.get(this.url + alert_id).then((response) => {
      return response.data;
    });
  }
  async update(alert_id: number, alert_form: AlertForm, alertFormNotisceSetting: AlertFormNoticeSetting, projectIds: ProjectIds): Promise<AlertForm> {
    return axios.put(this.url + alert_id, { alert_form: alert_form, alert_form_notice_setting:  alertFormNotisceSetting, project_ids: projectIds }).then((response) => {
      return response.data;
    });
  }
  async getFormReport(alert_id: number, params: GetFormReportParams ): Promise<AlertFormReport> {
    return axios.get(this.url + alert_id + "/report" ,{ params : params }).then((response) => {
      return response.data;
    });
  }
}
